<template>
  <div class="type-select" v-click-outside="handleClickOutside">
    <div class="overlay" v-if="isOpen" @click="handleClickOutside"></div>
    <input type="text" class="form-control type-input" :class="{ 'is-invalid': hasError }" :value="selectionLabel" readonly @click="open">

    <div class="type-select-panel" v-if="isReady && isOpen">
      <div class="list-group list-group-flush">
        <div class="list-group-item">
          <div class="search-input">
            <span class="icon">
              <fa-icon :icon="['fas', 'search']"></fa-icon>
            </span>
            <input type="text" class="form-control" placeholder="Rechercher par nom ou par code..." v-model="searchTerm">
          </div>
          <div class="mt-2">
            <a href="" v-if="searchTerm" class="clear-search" @click.prevent="resetSearch">
              Réinitialiser la recherche
            </a>
          </div>
        </div>
        <template v-if="!searchTerm">
          <a href="" class="list-group-item list-group-item-action" v-if="currentLevelId !== 'root'" @click.prevent="goBack">
            <fa-icon :icon="['fas', 'chevron-left']" class="mr-3"></fa-icon>
            <strong>{{ currentCategoryLabel }}</strong>
          </a>
          <a href="" class="list-group-item list-group-item-action d-flex justify-content-between" @click.prevent="handleSelectParent(currentLevelId)" v-if="currentLevelId !== 'root'">
            <div><em>Utiliser la catégorie &laquo; {{ currentCategoryLabel }} &raquo;</em></div>
          </a>
          <div class="category-list">
            <template v-for="category in sortedCategories">
              <a :key="category.id" href="" class="list-group-item list-group-item-action d-flex justify-content-between" @click.prevent="handleSelect(category)">
                <div>{{ category.name }}</div>
                <div class="d-flex align-items-center">
                  <div class="category-code mr-4">{{ category.shortId }}</div>
                  <div class="chevron-wrapper">
                    <fa-icon v-if="hasChildren(category)" :icon="['fas', 'chevron-right']"></fa-icon>
                  </div>
                </div>
              </a>
            </template>
          </div>
        </template>
        <template v-else>
          <template v-if="searchResults.length">
            <div class="category-list">
              <template v-for="category in searchResults">
                <a :key="category.id" href="" class="list-group-item list-group-item-action d-flex justify-content-between" @click.prevent="handleSelect(category)">
                  <div>
                    {{ category.name }}
                    <div v-if="category.parentId" class="category-path d-flex align-items-center">
                      <div class="mr-1">Dans :</div>
                      <category-path :parent-id="category.parentId" />
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="category-code mr-4">{{ category.shortId }}</div>
                    <div class="chevron-wrapper">
                      <fa-icon v-if="hasChildren(category)" :icon="['fas', 'chevron-right']"></fa-icon>
                    </div>
                  </div>
                </a>
              </template>
            </div>
            <div class="list-group-item">
              <label>Vous ne trouvez pas la catégorie recherchée?
                <router-link to="/">Suggérez-nous une nouvelle catégorie!</router-link>
              </label>
            </div>
          </template>
          <div v-else class="list-group-item">
            <div class="no-result">
              <div>Aucune catégorie ne correspond.</div>
              <div>Veuillez modifier votre recherche.</div>
              <p class="mt-3">N'hésitez pas à nous contacter si vous pensez qu'une catégorie est manquante!</p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { deburr, find, orderBy, filter, take } from 'lodash-es'
import CategoryPath from './CategoryPath'

export default {
  props: {
    value: {
      required: false,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CategoryPath
  },
  data () {
    return {
      selectedValue: null,
      isReady: false,
      isOpen: false,
      currentLevelId: null, // 'root' or UUID of category
      searchTerm: ''
    }
  },
  computed: {
    selectedCategory () {
      if (this.selectedValue) {
        return find(this.$store.state.productCategories.collection, { id: this.selectedValue })
      }

      return null
    },
    selectionLabel () {
      return this.selectedCategory ? this.selectedCategory.name : ''
    },
    currentCategory () {
      if (this.currentLevelId !== 'root') {
        return find(this.$store.state.productCategories.collection, { id: this.currentLevelId })
      }

      return null
    },
    currentCategoryLabel () {
      return this.currentCategory ? this.currentCategory.name : 'Racine'
    },
    currentParentId () {
      return this.currentCategory ? this.currentCategory.parentId : null
    },
    categories () {
      if (this.currentLevelId) {
        const parentId = this.currentLevelId === 'root' ? null : this.currentLevelId
        const categories = this.$store.getters['productCategories/getCategoriesByParent'](parentId)
        return [...categories]
      }

      return []
    },
    sortedCategories () {
      return orderBy(this.categories, ['order'], ['asc'])
    },
    searchResults () {
      if (this.$store.state.productCategories.collection) {
        return take(filter(this.$store.state.productCategories.collection, (category) => {
          return (category.shortId && String(category.shortId).indexOf(this.searchTerm) === 0) ||
            (category.name && deburr(category.name).toLowerCase().includes(deburr(this.searchTerm).toLowerCase()))
        }), 15)
      }

      return []
    }
  },
  methods: {
    initContext () {
      if (this.selectedCategory) {
        this.currentLevelId = this.selectedCategory.parentId
      } else {
        this.currentLevelId = 'root'
      }

      this.isReady = true
    },
    goBack () {
      const targetId = this.currentParentId ? this.currentParentId : 'root'
      this.currentLevelId = targetId
    },
    hasChildren (category) {
      const children = this.$store.getters['productCategories/getCategoriesByParent'](category.id)
      return Array.isArray(children) && children.length > 0
    },
    handleSelect (category) {
      if (!this.hasChildren(category)) {
        this.selectedValue = category.id
        this.$emit('input', category.id)
        this.isOpen = false
        return
      }

      this.currentLevelId = category.id
      this.resetSearch()
    },
    handleSelectParent (categoryId) {
      this.selectedValue = categoryId
      this.$emit('input', categoryId)
      this.isOpen = false
    },
    handleClickOutside () {
      this.isOpen = false
    },
    open () {
      this.isOpen = true
    },
    resetSearch () {
      this.searchTerm = ''
    }
  },
  watch: {
    value (val, oldVal) {
      if (val !== oldVal && val !== this.selectedValue) {
        this.selectedValue = val
      }
    }
  },
  async created () {
    if (this.value) {
      this.selectedValue = this.value
    }

    await this.$store.dispatch('productCategories/fetchOrRefresh')

    this.initContext()
  }
}
</script>

<style lang="scss" scoped>
.type-select {
  position: relative;
  width: 100%;

  .type-input {
    background-color: #fff;
  }

  .type-select-panel {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 1px 0 0 rgba(0,0,0,.2), 0 2px 3px 0 rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 4px;
    background: white;
    z-index: 110;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.chevron-wrapper {
  width: 10px;
}

.category-code {
  opacity: 0.8;
  font-size: 13px;
}

.search-input {
  position: relative;

  .form-control {
    padding-left: 35px;
  }

  .icon {
    position: absolute;
    left: 10px;
    top: 0;
    line-height: 38px;
    height: 38px;
    opacity: 0.7;
  }
}

.no-result {
  padding: 30px 0;
  opacity: 0.8;
  text-align: center;
}

.clear-search {
  font-size: 13px;
}

.category-path {
  font-size: 13px;
  opacity: 0.8;
}

.category-list {
  max-height: 360px;
  overflow-y: auto;
}
</style>
